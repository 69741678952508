import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import YearToDateOccupancy from './YearToDateOccupancy';
import FutureBookings from './FutureBookings';

export default class RoomTypeBooking extends Component {
  static propTypes = {
    statistics: PropTypes.object.isRequired,
  };

  render() {
    const { statistics } = this.props;
    return (
      <Segment>
        <FutureBookings statistics={statistics} />
        <YearToDateOccupancy statistics={statistics} />
      </Segment>
    );
  }
}
